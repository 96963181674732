
.cn_row {
    display: flex;
    flex-wrap: wrap;
}
.cn_col {
    flex: 1 0 18%; /* The important bit. This percentage decides your columns. 
 The percent can be px. It just represents your minimum starting width.
  */
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cn_game-card {
    flex-grow: 1;
    min-height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    border-radius: 5px;
    font-size: 1.3rem;
    font-weight: 500;
    text-transform: uppercase;
    transition-property: background, border;
    transition-duration: 2.5s, 0.2s;
    color: #000000;
}

@media(max-width: 1600px) {
    .cn_game-card {
        font-size: 1.2rem;
        min-height: 70px;
    }
}
@media(max-width: 1300px) {
    .cn_game-card {
        font-size: 1.1rem;
        min-height: 50px;
    }
}
@media(max-width: 1100px) {
    .cn_game-card {
        font-size: 1rem;
    }
}

.cn_game-table.cn_game--active .cn_game-card:hover {
    cursor: pointer;
    border-color: #000000;
}

.cn_game-table.cn_game--active .cn_game-card--revealed:hover {
    cursor: auto;
    border-color: transparent;
}

.alert-black {
    color: #fff;
    background-color: #1b1e21;
    border-color: #000000;
}

.cn_game-card--revealed.alert-danger {
    background: #dc3545;
}

.cn_game-card--revealed.alert-primary {
    background: #007bff;
}

.cn_game-card--revealed.alert-secondary {
    background: #cfcfab;
}
